<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="dialog" persistent>
                <v-card theme="dark" rounded="lg">
                    <v-toolbar dark color="default">
                        <v-toolbar-title>
                            Keranjang & Wishlist
                        </v-toolbar-title>
                        <!-- <v-spacer></v-spacer> -->
                        <v-toolbar-items>
                            <v-btn icon dark @click="hideDialog()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <div>
                            <div v-if="token">
                                <div class="mb-5" v-if="product.allowToOrder == false">
                                    <v-alert dense text outlined type="warning">
                                        Produk belum dapat dipesan, Saat ini stok dalam proses perhitungan. Tambahkan ke <strong>wishlist</strong> untuk notifikasi info ketersediaan.
                                    </v-alert>
                                </div>
                                <div>
                                    <ul v-if="product.type == 'VARIED'" class="list-unstyled">
                                        <li class="" v-for="(optionType, index) in product.optionTypes" :key="optionType.id" style="    list-style: none;">
                                            <div>
                                                <span v-if="optionType.id == 'size'" class="">
                                                    Pilihan Ukuran
                                                </span>
                                                <span v-if="optionType.id == 'color'" class="">
                                                    Pilihan Warna
                                                </span>
                                                <div v-if="optionType.id == 'size'" style="">
                                                    <template v-for="option in optionSelectionModel[index]" :key="option.value">
                                                        <v-chip variant="flat" class="ma-2" v-if="option.availableInStockExists"
                                                            :color="option.selected ? 'red' : ''"
                                                            v-on:click.prevent="chooseVariants({ optionTypeIdx: index, variantValue: option.value })">
                                                            {{ option.displayValue }}
                                                        </v-chip>
                                                        <v-chip variant="flat" class="ma-2 lineTr" v-else>
                                                            {{ option.displayValue }}
                                                        </v-chip>
                                                    </template>
                                                </div>
                                                <div v-if="optionType.id == 'color'" class="mb-4 mt-2">
                                                    <template v-for="option in optionSelectionModel[index]" :key="option.value">
                                                        <span class="color-select" :style="{ padding: '5px' }" v-if="option.availableInStockExists"
                                                            :class="option.selected ? 'active' : ''"
                                                            v-on:click.prevent="chooseVariants({ optionTypeIdx: index, variantValue: option.value })">
                                                            <span :style="{ 
                                                                'background-color': option.displayValue.replace(' ', ''),
                                                                width: '100%',
                                                                height: '100%',
                                                            }">&nbsp;&nbsp;&nbsp;</span>
                                                        </span>
                                                        <span class="color-select lineTr" :style="{ padding: '5px' }" v-else>
                                                            <span :style="{ 
                                                                'background-color': option.displayValue.replace(' ', ''),
                                                                width: '100%',
                                                                height: '100%',
                                                            }"></span>
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="mt-2 mb-2">
                                    Jumlah
                                </div>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field style="min-width:150px" id="subHeaderQty" type="text" filled small
                                            v-model="wantedQty" 
                                            append-icon="mdi-plus"
                                            :prepend-icon="'mdi-minus'"
                                            @click:append="modifyQty('inc')"
                                            @click:prepend="modifyQty('dec')"
                                            @change="inputChange($event)"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="mt-0 pt-0">
                                        <v-btn style="background-color: #694177 !important;" class="btn-primary" rounded block variant="flat" color="primary" text @click="addItem()" :loading="isLoadingAdd"
                                            :disabled="product.allowToOrder == false">
                                            <v-icon class="mr-1">mdi-cart</v-icon>
                                            Tambah Ke Keranjang
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="mt-0 pt-0">
                                        <v-btn rounded block text @click="addWishlist()" color="white" :loading="isLoadingAdd">
                                            <v-icon class="mr-1">mdi-heart</v-icon>
                                            Tambah Ke Wishlist
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="mt-0 pt-0">
                                        <v-btn rounded block text variant="flat" color="success" @click="orderViaWhatsApp()">
                                            <v-icon class="mr-1">mdi-whatsapp</v-icon>
                                            Order Via WhatsApp
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <div class="mb-5">
                                    <input type="text" id="subHeaderQty" v-model="wantedQty" class="disnone"/>

                                    <ul v-if="product.type == 'VARIED'" class="list-unstyled">
                                        <li class="" v-for="(optionType, index) in product.optionTypes" :key="optionType.id" style="list-style: none;">
                                            <div>
                                                <span v-if="optionType.id == 'size'" class="">
                                                    Pilihan Ukuran
                                                </span>
                                                <div v-if="optionType.id == 'size'" style="">
                                                    <template v-for="option in optionSelectionModel[index]" :key="option.value">
                                                        <v-chip variant="flat" class="ma-2" v-if="option.availableInStockExists"
                                                            :color="option.selected ? 'red' : ''"
                                                            v-on:click.prevent="chooseVariants({ optionTypeIdx: index, variantValue: option.value })">
                                                            {{ option.displayValue }}
                                                        </v-chip>
                                                        <v-chip variant="flat" class="ma-2 lineTr" v-else>
                                                            {{ option.displayValue }}
                                                        </v-chip>
                                                    </template>
                                                </div>

                                                <span v-if="optionType.id == 'color'" class="">
                                                    Pilihan Warna
                                                </span>
                                                <div v-if="optionType.id == 'color'" class="mb-4 mt-2">
                                                    <template v-for="option in optionSelectionModel[index]" :key="option.value">
                                                        <span class="color-select" :style="{ padding: '5px' }" v-if="option.availableInStockExists"
                                                            :class="option.selected ? 'active' : ''"
                                                            v-on:click.prevent="chooseVariants({ optionTypeIdx: index, variantValue: option.value })">
                                                            <span :style="{ 
                                                                'background-color': option.displayValue.replace(' ', ''),
                                                                width: '100%',
                                                                height: '100%',
                                                            }">&nbsp;&nbsp;&nbsp;</span>
                                                        </span>
                                                        <span class="color-select lineTr" :style="{ padding: '5px' }" v-else>
                                                            <span :style="{ 
                                                                'background-color': option.displayValue.replace(' ', ''),
                                                                width: '100%',
                                                                height: '100%',
                                                            }"></span>
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn rounded block text variant="flat" color="success" @click="orderViaWhatsApp()">
                                            <v-icon class="mr-1">mdi-whatsapp</v-icon>
                                            Order Via WhatsApp
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn rounded block variant="flat" color="primary" text @click="login()" style="background-color: #694177 !important;" >
                                            <v-icon class="mr-1">mdi-login</v-icon>
                                            Login
                                        </v-btn>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <v-btn rounded block variant="flat" color="white" text @click="getGoogleLink()" :loading="isLoadingGoogle">
                                            <v-icon class="mr-1">mdi-google</v-icon>
                                            Login Dengan Google
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                                <br>
                                <v-divider class=""></v-divider>
                                <v-card-actions>
                                        <v-btn text  @click="hideDialog()">
                                            Keluar
                                        </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="register()">
                                        Daftar
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Buffer } from 'buffer';
import { event } from 'vue-gtag'

export default {
    name: "Dialog Cart",

    data: () => {
        return {
            dialog: false,

            isLoadingGoogle: false,
            isLoadingWishlist: false
        }
    },

    computed: {
        ...mapState({
            token: state => state.token,
            customerType: state => state.customerType,
            product: state => state.Product.product,
            optionSelectionModel: state => state.Cart.optionSelectionModel,
            wantedQty: state => state.Cart.wantedQty,
            selectedVariantId: state => state.Cart.selectedVariantId,
            isLoadingAdd: state => state.Cart.isLoadingAdd,
            allOptionSelected: state => state.Cart.allOptionSelected
        })
    },

    methods: {
        ...mapActions({
            addProduct: "Cart/addProduct",
            getCartLines: "Cart/getCartLines",
            postFacebookPixel: "actPostEventFbConversion"
        }),

        showDialog() {
            this.dialog = true
        },

        hideDialog() {
            this.dialog = false
            this.$store.commit('Cart/setWantedQty', 1);
        },

        inputChange(event) {
            this.$store.commit('Cart/setWantedQty', event.target.value);
        },

        async chooseVariants(data) {
            await this.$store.dispatch("Cart/chooseVariants", data);
            this.modifyQty('check');
        },

        register() {
            this.hideDialog()
            this.$emit('open-register')
        },

        login() {
            this.hideDialog()
            this.$emit('open-login')
        },

        async getGoogleLink() {
            try {
                this.isLoadingGoogle = true

                const json = JSON.stringify({
                    location: window.location
                });

                const base64String =  Buffer.from(json).toString("base64");

                const response = await this.$axios.get("/users/oauth/google", {
                    params: {
                        callback: window.location.protocol.concat("//").concat(window.location.hostname).concat("/"),
                        state: base64String,
                    },
                });

                window.location = response.data.url;

                this.isLoadingGoogle = false
            } catch (error) {
                this.isLoadingGoogle = false

                if(error.response && error.response.data) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('Error Daftar Google')
                }
            }
        },

        orderViaWhatsApp() {
            let text = `${this.product.name} - ${this.product.localSku}\n`
                text += process.env.VUE_APP_COMMERCE_WEB_URL + `/id/${this.product.slug}\n`
                text += `\nSaya ingin memesan produk ini`;

            const qs = new URLSearchParams()
            qs.append("text", text)

            const params = qs.toString()
            const number = process.env.VUE_APP_WA_NUMBER

            window.open(`http://wa.me/${number}?${params}`, '_blank');

            
            if(this.customerType == 'retail') {
                this.addGoogleAnalytics('whatsapp')
            }
        },

        async addWishlist() {
            try {
                if (!this.allOptionSelected) {
                    this.$toast.error('Pilih varian terlebih dahulu')
                    return
                }

                this.isLoadingWishlist = true

                const res = await this.$axios.post(`/wishlist`, {
                    productId: this.product.id,
                    variantId: this.selectedVariantId
                });

                this.isLoadingWishlist = false

                if(res.status == 200) {
                    this.$toast.success('Berhasil menambahkan produk ke wishlist')
                    this.hideDialog()

                    if(this.customerType == 'retail') {
                        this.addGoogleAnalytics('wishlist')
                        this.addFacebookPixel('wishlist')
                    }
                }
                
            } catch (error) {
                this.isLoadingWishlist = false
                if(error.response && error.response.data) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('Gagal menambahkan produk ke wishlist')
                }
            }
        },

        async addItem() {
            if (!this.allOptionSelected) {
                this.$toast.error('Pilih varian terlebih dahulu')
                return
            }

            const item = {
                productId: this.product.id,
                variantId: this.selectedVariantId
            }

            const res = await this.addProduct(item)

            if(res && res.status == 200) {
                this.$toast.success('Berhasil menambahkan produk ke keranjang')
                this.hideDialog()
                this.getCartLines()

                if(this.customerType == 'retail') {
                    this.addGoogleAnalytics('cart')
                    this.addFacebookPixel('cart')
                }
            }
        },

        modifyQty: function (action) {
            if (this.optionSelectionModel) {
                let selectedVariant = this.optionSelectionModel[0].find((val) => val.selected == true);
                let selectedColorVariant = "";

                if (action == 'check') this.$store.commit('Cart/setWantedQty', document.getElementById('subHeaderQty').value);
                
                if (!selectedVariant) {
                    alert('Pilih varian terlebih dahulu');
                    return;
                }

                if (selectedVariant.subVariant) {
                    selectedColorVariant = this.optionSelectionModel[0].find((val) => val.selected == true);
                    selectedVariant = selectedColorVariant.subVariant.find((val) => val.selected == true);
                }

                if (action == "inc") this.$store.commit('Cart/incWantedQty', selectedVariant);
                if (action == "dec") this.$store.commit('Cart/decWantedQty', selectedVariant);
                if (action == "check") this.$store.commit('Cart/checkStock', selectedVariant);
            }
        },

        addGoogleAnalytics: function(kind) {
            const selectedProduct = {
                item_id: this.product.localSku,
                item_name: this.product.name,
                currency: this.product.currency,
                discount: this.product.discounts,
                index: 0,
                item_brand: this.product.shop.name,
                item_category: this.product.categories[0].name,
                item_variant: this.selectedVariantId,
                price: this.product.finalPrice,
                quantity: this.wantedQty
            }

            if(kind == 'cart') {
                event('add_to_cart', {
                    currency: this.product.currency,
                    value: this.product.localPrice,
                    items: [selectedProduct]
                })
            } else if(kind == 'wishlist') {
                event("add_to_wishlist", {
                    currency: this.product.currency,
                    value: this.product.localPrice,
                    items: [selectedProduct]
                });
            } else if(kind == 'whatsapp') {
                event("order_whatsapp", {
                    currency: this.product.currency,
                    value: this.product.localPrice,
                    items: [selectedProduct]
                });
            }
        },

        addFacebookPixel: function(kind) {
            let data = {
                event_name: "",
                action_source: "website",
                custom_data: {
                    currency: this.product.currency,
                    value: this.product.localPrice,
                    content_ids: [this.product.localSku],
                    content_type: "product",
                    contents: [{
                        id: this.product.localSku,
                        quantity: this.product.qty,
                        item_price: this.product.finalPrice,
                    }]
                }
            }

            if(kind == 'cart') {
                data.event_name = "AddToCart"
            } else if(kind == 'wishlist') {
                data.event_name = "AddToWishlist"
            }

            this.postFacebookPixel(data)
        }
    }
}
</script>

<style>
.color-select{
    width:20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.color-select::after{
    background-color: none;
}

.active {
    border: 1px solid;
}

.lineTr{
     text-decoration: line-through;
}

.bg-primary{
    background-color:#694177 !important;
}

.btn-primary{
    background-color:#694177 !important;
}

</style>