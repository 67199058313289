import { createWebHistory, createRouter } from "vue-router";
import MyHome from "@/views/MyHome.vue"
import MyFlipbook from "@/views/MyFlipbook.vue"
import MyFlipbookOld from "@/views/MyFlipbookOld.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: MyHome,
    meta: {
      title: 'Tuneeca Catalogue',
    },
  },
  {
    path: "/:slug",
    name: "Flipbook",
    component: MyFlipbook,
    meta: {
      title: 'Tuneeca Catalogue',
    },
  },
  {
    path: "/Flipbook",
    name: "Flipbook Old",
    component: MyFlipbookOld,
    meta: {
      title: 'Tuneeca Catalogue',
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta.title
})

export default router;