<template>
    <v-app class="aw">
        <v-container class="ma-0 pa-0" justify-center>
            <div v-if="isTour" class="opacity"></div>
            <v-progress-circular v-if="isLoading"
                :size="70"
                :width="7"
                color="purple"
                indeterminate
            ></v-progress-circular>
            <v-layout row v-else>
                <!-- <audio autoplay class="disnone"
                    src="https://media.geeksforgeeks.org/wp-content/uploads/20220913101124/audiosample.ogg"
                    type="audio/mp3"
                ></audio> -->
                <v-flex class="ma-0 pa-0">
                    <VueBookEffects ref="flipbook"
                        class="flipbook" 
                        v-slot="bookEffect" 
                        :pages="pages" 
                        :pagesHiRes="pagesHiRes" 
                        :start-page="startPage"
                    >
                        <v-flex xs12 class="pt-2 action-bar text-xs-center">
                            <v-btn class="ma-0" v-if="!token" data-v-step="login"
                                icon="mdi-login" 
                                variant="text" 
                                size="small"
                                @click="showDialogLogin()"
                            ></v-btn>
                            <v-btn class="ma-0" v-else
                                icon="mdi-logout" 
                                variant="text" 
                                size="small"
                                @click="showDialogLogout()"
                            ></v-btn>
                            <v-btn class="ma-0" data-v-step="all-katalog"
                                icon="mdi-apps" 
                                variant="text" 
                                size="small"
                                @click="dialog = true"
                            ></v-btn>
                            <v-btn class="ma-0" data-v-step="before"
                                :class="{ disabled: !bookEffect.canFlipLeft }" 
                                icon="mdi-chevron-left" 
                                variant="text" 
                                size="small"
                                @click="bookEffect.flipLeft"
                            ></v-btn>
                            <v-btn class="ma-0" data-v-step="zoom-in"
                                :class="{ disabled: !bookEffect.canZoomIn }" 
                                icon="mdi-plus" 
                                variant="text" 
                                size="small"
                                @click="bookEffect.zoomIn"
                            ></v-btn>
                            <span class="ma-0 font-weight-medium subheading">Page</span>
                            <span class="font-weight-medium subheading">{{ bookEffect.page }}</span>
                            <span class="font-weight-medium subheading">/</span>
                            <span class="mr-0 font-weight-medium subheading">{{ bookEffect.numPages }}</span>
                            <v-btn class="ma-0" data-v-step="zoom-out"
                                :class="{ disabled: !bookEffect.canZoomOut }" 
                                icon="mdi-minus" 
                                variant="text" 
                                size="small"
                                @click="bookEffect.zoomOut"
                            ></v-btn>
                            <v-btn class="ma-0" data-v-step="after"
                                :class="{ disabled: !bookEffect.canFlipRight }" 
                                icon="mdi-chevron-right" 
                                variant="text" 
                                size="small"
                                @click="bookEffect.flipRight"
                            ></v-btn>
                            <v-btn class="ma-0" v-if="token" stacked
                                variant="text" 
                                size="small"
                                @click="goToCartCommerce()"
                            >
                                <v-badge color="error" :content="totalCartsQty" size="small">
                                    <v-icon>mdi-cart-outline</v-icon>
                                </v-badge>
                            </v-btn>
                        </v-flex>
                        <div v-if="pageOrientation == 'landscape'">
                            <desktop class="action-bar mb-5">
                                <!-- <div class="wrapp-music" :class="{
                                    'btn-music-right': flipbook.canFlipLeft && flipbook.canFlipRight,
                                    'btn-music-left': !flipbook.canFlipLeft && flipbook.canFlipRight,
                                    'disnone': !flipbook.canFlipLeft && !flipbook.canFlipRight
                                }">
                                    <v-btn icon @click="toggleSound()" color="black" class="white--text">
                                        <v-icon>
                                            {{ mutedAudio ? 'mdi-music-note-off' : 'mdi-music-note' }}
                                        </v-icon>
                                    </v-btn>
                                </div> -->
                                <div class="wrapp-button">
                                    <span class="link-detail-pc-left">
                                        <a class="float-left" v-if="isShow(flipbook.page - 1, 'web')" 
                                            :href="generateUrl(flipbook.page - 1)" target="_blank" rel="noopener noreferrer">
                                            <!-- <img  style="width:22px" src="../assets/info.png"/> -->
                                            <img style="width:25px" src="../assets/buy.png"/>
                                        </a>
                                        <a class="float-left" v-if="isShow(flipbook.page - 1, 'cart')" @click="goToProduct(flipbook.page - 1)">
                                            <img style="width:25px" src="../assets/buy.png"/>
                                        </a>
                                        <a class="float-left" v-if="isShow(flipbook.page - 1, 'cart')" @click="openCartDialog(flipbook.page - 1)">
                                            <img style="width:25px" src="../assets/cart.png"/>
                                        </a>
                                    </span>

                                    <span class="link-detail-pc-right">
                                        <a class="float-left" v-if="isShow(flipbook.page, 'web') && flipbook.canFlipLeft && flipbook.canFlipRight" 
                                            :href="generateUrl(flipbook.page)" target="_blank" rel="noopener noreferrer">
                                            <!-- <img  style="width:22px" src="../assets/info.png"/> -->
                                            <img style="width:25px" src="../assets/buy.png"/>
                                        </a>
                                        <a class="float-left" v-if="isShow(flipbook.page, 'cart') && flipbook.canFlipLeft && flipbook.canFlipRight" 
                                            @click="goToProduct(flipbook.page)">
                                            <img style="width:25px" src="../assets/buy.png"/>
                                        </a>
                                        <a class="float-left" v-if="isShow(flipbook.page, 'cart') && flipbook.canFlipLeft && flipbook.canFlipRight" 
                                            @click="openCartDialog(flipbook.page)">
                                            <img style="width:25px" src="../assets/cart.png"/>
                                        </a>
                                    </span>
                                    <span data-v-step="first"></span>
                                </div>
                            </desktop>
                        </div>
                        <div v-else>
                            <mobile class="action-bar">
                                <!-- <div class="wrapp-music-mobile">
                                    <v-btn icon @click="toggleSound()" color="black" class="white--text">
                                        <v-icon>
                                            {{ mutedAudio ? 'mdi-music-note-off' : 'mdi-music-note' }}
                                        </v-icon>
                                    </v-btn>
                                </div> -->
                                <span class="link-detail">
                                    <a class="float-left" v-if="isShow(flipbook.page - 1, 'cart')" @click="goToProduct(flipbook.page - 1)">
                                        <img style="width:25px" src="../assets/buy.png"/>
                                    </a>
                                    <a class="float-left" v-if="isShow(flipbook.page - 1, 'cart')" @click="openCartDialog(flipbook.page - 1)">
                                        <img style="width:25px" src="../assets/cart.png"/>
                                    </a>
                                    <a class="float-left" v-if="isShow(flipbook.page - 1, 'web')" :href="generateUrl(flipbook.page - 1)" target="_blank" rel="noopener noreferrer">
                                        <img  style="width:22px" src="../assets/info.png"/>
                                    </a>
                                    <span data-v-step="first"></span>
                                </span>
                            </mobile>
                        </div>
                    </VueBookEffects>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" width="1200px">
            <v-card theme="dark" rounded="lg">
                <v-toolbar>
                    <v-toolbar-title>Overview</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <span v-for="(tab, index) in tabs" :key="index+'tab'" class="mr-2">
                                <v-btn class="btn-primary" :color="selectedTab == index ? 'primary' : 'default'" @click="formatOverview(index, tab)" style="background-color: #694177 !important;">
                                    {{ tab.min == 0 ? 1 : tab.min }} ... {{ tab.max }}
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="4" lg="3" v-for="(overview, index) in overviews" :key="index"
                            @click="flipToPage(overview.image1.numb)">
                            <v-row>
                                <v-col cols="6" md="6" lg="6" class="pr-0">
                                        <span class="pageNumWrapp" v-if="overview.image1 && overview.image1.src">
                                            <span class="pageNumLeft"> {{overview.image1.numb}}</span>
                                            <img class="video-icon" src="../assets/video-icon.png" v-if="overview.image1.type == 'video'" />
                                            <img class="img-fluid" :src="overview.image1.src" alt="flipbook" width="130px" v-else />
                                        </span>
                                </v-col>
                                <v-col cols="6" md="6" lg="6" class="pl-0">
                                     <span class="pageNumWrapp" v-if="overview.image2 && overview.image2.src"
                                @click="flipToPage(pageOrientation == 'landscape'? overview.image1.numb : overview.image2.numb)">
                                <span class="pageNumRight">{{overview.image2.numb}}</span>
                                <img style="" src="../assets/video-icon.png" v-if="overview.image2.type == 'video'" />
                                <img class="img-fluid" :src="overview.image2.src" alt="flipbook" width="130px" v-else />
                            </span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <DialogLogin 
            ref="login"
            @open-register="showDialogRegister" />

        <DialogLogout
            ref="logout" />

        <DialogCart 
            ref="cart"
            @open-login="showDialogLogin"
            @open-register="showDialogRegister" />
            
        <DialogRegister
            ref="register" />

        <v-tour 
            name="myTour" 
            :steps="tour.steps" 
            :options="tour.options"
            :callbacks="tour.callbacks"
        ></v-tour>
    </v-app>
</template>
<script>
import { event } from 'vue-gtag'
import { mapActions, mapState } from 'vuex'
// import Flipbook from 'flipbook-vue'

import VueBookEffects from '@/components/vue-book-effects'
import DialogLogin from '@/components/dialog-login.vue'
import DialogLogout from '@/components/dialog-logout.vue'
import DialogCart from '@/components/dialog-cart.vue'
import DialogRegister from '@/components/dialog-register.vue'

export default {

    name: "MyFlipbook",

    components: {
        // Flipbook,
        VueBookEffects,
        DialogLogin,
        DialogLogout,
        DialogCart,
        DialogRegister
    },

    data: () => {
        return {
            counter: 0,
            selectedTab: 0,
            startPage: 1,
            pageSize: 100,

            pageOrientation: String(),

            pages: Array(),
            pagesHiRes: Array(),
            overviews: Array(),
            tabs: Array(),
            
            detail: Object(),
            flipbook: Object(),

            isLoading: false,
            dialog: false,
            pausedAudio: false,
            mutedAudio: false,
            isTour: false,

            tour: {
                options: {
                    useKeyboardNavigation: false,
                    labels: {
                        buttonSkip: 'Tutup Panduan',
                        buttonPrevious: 'Sebelumnya',
                        buttonNext: 'Berikutnya',
                        buttonStop: 'Selesai'
                    }
                },
                steps: [
                    {
                        target: '[data-v-step="first"]',
                        content: `<img width="50px" alt="swipe" src="rotate.png" />
                        <br />
                        Swipe pada gambar untuk melihat gambar berikutnya`,
                    },
                    {
                        target: '[data-v-step="login"]',
                        content: 'Tombol login/daftar untuk dapat melakukan checkout',
                    },
                    {
                        target: '[data-v-step="all-katalog"]',
                        content: 'Tombol preview semua gambar di katalog',
                    },
                    {
                        target: '[data-v-step="before"]',
                        content: 'Tombol untuk pindah ke halaman sebelumnya',
                    },
                    {
                        target: '[data-v-step="zoom-in"]',
                        content: 'Tombol untuk memperbesar gambar',
                    },
                    {
                        target: '[data-v-step="zoom-out"]',
                        content: 'Tombol untuk memperkecil gambar',
                    },
                    {
                        target: '[data-v-step="after"]',
                        content: 'Tombol untuk pindah ke halaman berikutnya',
                    }
                ],
                callbacks: {
                    onFinish: null,
                    onSkip: null
                }
            }

        }
    },

    computed: {
        ...mapState({
            token: state => state.token,
            customerType: state => state.customerType,
            analytics: state => state.analytics,
            product: state => state.Product.product,
            totalCartsQty: state => state.Cart.totalCartsQty
        })
    },

    async created() {
        this.$store.commit('setContext', this)
        
        const token = this.$cookies.get('bearer')
        
        if(token) {
            this.$store.commit('setToken', token)
        }
        
        await this.pageInit()
        
        this.gtagConfig()
    },

    async mounted() {
        const slug = this.$route.params.slug
        
        if (slug) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                this.pageOrientation = "portrait"
            } else {
                this.pageOrientation = "landscape"
            }

            await this.getData(slug)

            this.flipbook = this.$refs.flipbook

            window.addEventListener("orientationchange", function() {
                if(screen.orientation == 'portrait-primary') {
                    this.pageOrientation = "portrait"
                } else {
                    this.pageOrientation = "landscape"
                }
            }, false);

            if(this.customerType == 'retail') {
                event('view_flipbook', {
                    flipbook_name: this.detail.name,
                    flipbook_slug: this.detail.slug,
                    percent_opened: 20
                })
            }
        } else {
            this.$router.push({
                name: 'Home'
            })
        }

        const tour = localStorage.getItem("tour")

        if(this.token == null && tour == null || tour == "") {
            this.isTour = true
            this.tour.callbacks.onFinish = this.stepFinish
            this.tour.callbacks.onSkip = this.stepSkip
            
            this['$tours']['myTour'].start()
        }

    },
    
    watch: {
        'flipbook.page'(val, oldVal) {
            if(oldVal == undefined || val > oldVal) {
                this.preloadNextImagePage(val)
            }
                           
            if(val > 1) {
                const percent = (val / this.flipbook.numPages) * 100
                let counter = 0

                if(percent >= 80) {
                    counter = 100
                } else if(percent >= 60) {
                    counter = 80
                } else if(percent >= 40) {
                    counter = 60
                } else if(percent >= 20) {
                    counter = 40
                } else {
                    counter = 20
                }

                if(counter > this.counter) {
                    this.counter = counter

                    const data = {
                        flipbook_name: this.detail.name,
                        flipbook_slug: this.detail.slug,
                        percent_opened: counter
                    }

                    if(this.customerType == 'retail') {
                        if(percent >= 80) {
                            event('view_flipbook_five', data)
                        } else if(percent >= 60) {
                            event('view_flipbook_four', data)
                        } else if(percent >= 40) {
                            event('view_flipbook_three', data)
                        } else if(percent >= 20) {
                            event('view_flipbook_two', data)
                        }
                    }


                    // this.$gtag.event('view_flipbook', data)
                }
            }
        }
    },

    methods: {
        ...mapActions({
            getDetailProduct: "Product/getDetail",
            getCartLines: "Cart/getCartLines",
            pageInit: "pageInit"
        }), 
        gtagConfig() {
            const existingScript = document.querySelector(`script[src*="googletagmanager"]`);

            if (existingScript) {
                document.head.removeChild(existingScript);
            }

            // Create a script element for GA4
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.src = `https://www.googletagmanager.com/gtag/js?id=${this.analytics.googleId}`;
            document.head.appendChild(script);

            // Initialize GA4 with the dynamic Measurement ID
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', this.analytics.googleId, {
                page_path: window.location.pathname,
            });
        },
        showDialogLogin() {
            this.$refs.login.showDialog()
        },
        showDialogLogout() {
            this.$refs.logout.showDialog()
        },
        showDialogCart() {
            this.$refs.cart.showDialog()
        },
        showDialogRegister() {
            this.$refs.register.showDialog()
        },
        stepFinish() {
            localStorage.setItem("tour", "finish");
            this.isTour = false
        },
        stepSkip() {
            localStorage.setItem("tour", "skip");
            this.isTour = false
        },
        toggleAudio() {
            const audio = document.querySelector('audio')

            if (audio.paused) {
                audio.play();
            } else {
                audio.pause();
            }
        },
        toggleSound() {
            const audio = document.querySelector('audio')

            audio.muted = !audio.muted;
            this.mutedAudio = audio.muted == false ? true : false;
        },
        flipToPage(page) {
            this.startPage = page
            this.dialog = false
        },
        pageBook(index) {
            if(this.detail && this.detail.catalogPages && this.detail.catalogPages.length > 0) {
                const pageBook = this.detail.catalogPages[index]

                if(pageBook) return pageBook
            }

            return null
        },
        async openCartDialog(index) {
            const pageBook = this.pageBook(index)

            if(pageBook && pageBook.promotedProducts[0]) {
                await this.getDetailProduct(pageBook)

                if(this.product && this.product.id) {
                    this.showDialogCart()
                }
                
            }
        },
        async goToProduct(index) {
            const pageBook = this.pageBook(index)

            if(pageBook && pageBook.promotedProducts[0]) {
                await this.getDetailProduct(pageBook)
                
                if(this.product && this.product.id) {
                    const url = process.env.VUE_APP_COMMERCE_WEB_URL + `/id/${this.product.slug}`
                    window.open(url, '_blank');
                }
            }
        },
        async goToCartCommerce() {
            try {
                const { data } = await this.$axios.get('/users/generate-otp')

                if(data) {
                    const url = process.env.VUE_APP_COMMERCE_WEB_URL + `/id/verif/flipbook?otp=${data.otp}&username=${data.username}`
    
                    window.open(url, '_blank');
                }

            } catch (error) {
                console.log("get-user-otp", error)
            }
        },
        generateUrl(index) {
            const pageBook = this.pageBook(index)

            if(pageBook && pageBook.webUrl) return pageBook.webUrl
            
            return ""
        },
        isShow(index, kind) {
            const pageBook = this.pageBook(index)

            if(kind == 'web') {
                if(pageBook && pageBook.webUrl) return true
            }
            if(kind == 'cart') {
                if(pageBook && pageBook.promotedProducts[0]) return true  
            }
            
            return false
        },
        async getData(slug) {
            try {
                this.isLoading = true
                const { data } = await this.$axios.get(`/catalog/${slug}`)

                const pages = [null]
                const pagesHiRes = [null]

                data.catalogPages.sort((a, b) => a.numb - b.numb)

                // https://source.unsplash.com/random/1080x1920
                data.catalogPages.forEach(e => {
                    if(e.youtubeUrl) {
                        const split = e.youtubeUrl.split("/")
                        const last = split[split.length - 1]
                        let id = last

                        if(last.includes("?")){
                            const index = last.indexOf("?")
                            id = last.slice(0, index)
                        } 
                        
                        pages.push(e.youtubeUrl + `&autoplay=1&loop=1&controls=0&modestbranding=0&rel=0&playlist=${id}`)
                        pagesHiRes.push(e.youtubeUrl + `&autoplay=1&loop=1&controls=0&modestbranding=0&rel=0&playlist=${id}`)
                    } else {
                        if(this.pageOrientation == 'portrait') {
                            pages.push(e.displayImage.styles.small.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                        } else {
                            pages.push(e.displayImage.styles.large.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                        }
                        if(e.displayImage.styles.original) {
                            pagesHiRes.push(e.displayImage.styles.original.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                        } else {
                            pagesHiRes.push(e.displayImage.originUri.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                        }
                    }
                })

                this.pagesHiRes = pagesHiRes
                this.pages = pages
                this.detail = data

                const tabs = []
                const totalElements = data.catalogPages.length
                const totalPage = totalElements/this.pageSize <= 1 ? 1 : Math.round(totalElements/this.pageSize)

                for (let index = 0; index < totalPage; index++) {
                    if(index == totalPage - 1) {
                        tabs.push({ 
                            min: (index * this.pageSize), 
                            max: totalElements
                        })
                    } else {
                        tabs.push({ 
                            min: (index * this.pageSize), 
                            max: (this.pageSize - 1) + ( index * this.pageSize ) 
                        })
                    }
                }
                this.tabs = tabs
                this.formatOverview(0, tabs[0])

                this.isLoading = false
            } catch (error) {
                this.isLoading = false

                if(error.response && error.response.status == 404) {
                    this.$toast.error(error.response.data.message);
                } else {
                    console.log("error", error)
                }
            }
        },
        formatOverview(index, tab) {
            let catalogPages = []
            const overviews = []

            if(index == 0) {
                overviews.push({
                    image1: {
                        numb: 0,
                        src: null,
                        type: 'image'
                    }
                })

                catalogPages = this.detail.catalogPages.slice(tab.min, tab.max)
            } else {
                catalogPages = this.detail.catalogPages.slice(tab.min - 1, tab.max)
            }

            catalogPages.forEach((e, i) => {
                const overview = overviews[overviews.length - 1]
                const data = {
                    numb: index == 0 ? i + 1 : (this.pageSize * index) + i,
                    src: e.youtubeUrl ? e.youtubeUrl : e.displayImage.styles.large.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"),
                    type: e.youtubeUrl ? 'video' : 'image'
                }

                if(!overview || (overview.image1 && overview.image2)) {
                    overviews.push({ image1: data })
                } else if(overview.image1) {
                    overviews[overviews.length - 1].image2 = data
                }
            })

            this.overviews = overviews
            this.selectedTab = index
        },
        preloadNextImagePage(pageNumber) {
            if(this.pageOrientation == 'portrait') {
                const pageLeft = this.detail.catalogPages[pageNumber]
                
                if(pageLeft && pageLeft.displayImage) {
                    this.preloadImage(pageLeft.displayImage.styles.small.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                    // console.log(pageLeft.displayImage.styles.small.src, pageLeft.numb)
                }
            } else {
                const add = pageNumber > 1 ? 1 : 0
                const pageLeft = this.detail.catalogPages[pageNumber + add]
                const pageRight = this.detail.catalogPages[pageNumber + 1 + add]

                if(pageLeft && pageLeft.displayImage) {
                    this.preloadImage(pageLeft.displayImage.styles.small.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                    // console.log(pageLeft.displayImage.styles.small.src, pageLeft.numb)
                }
                if(pageRight && pageRight.displayImage) {
                    this.preloadImage(pageRight.displayImage.styles.large.src.replace("pic.tuneeca.com", "pic-tuneeca.b-cdn.net"))
                    // console.log(pageRight.displayImage.styles.large.src, pageRight.numb)
                }

            }
        },
        preloadImage(src) {
            const img = new Image();
            img.src = src;
        }
    }
}
</script>
<style>

.v-btn--size-small {
    --v-btn-height: 24px;
}

.linkUrl{
        color: #efefef;
    font-size: 22px;
    margin-left: 10px;
}

.aw {
    background-color: #333;
    color: #ccc;
}

.action-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-detail {
       position: absolute;
    bottom: 26%;
    z-index: 10;
    /* left: 10px; */
}

.link-detail a, .link-detail-pc-left a, .link-detail-pc-right a {
    width: 50px;
    margin-right: 2px;
    height: 50px;
    background: black;
    text-align: center;
    opacity: 0.6;
    transition: 0.3s;
    padding: 12px 0;
    border-radius: 100%;
}

.link-detail a:last-child, .link-detail-pc-right a:last-child, .link-detail-pc-right a:last-child {
    margin-right: 0;
}

.disnone {
    display: none;    
}


.wrapp-music {
    position: absolute;
    top: 8%;
    z-index: 10;
    cursor: pointer;
    opacity: 0.6;
}

.btn-music-right {
    right: 11%;
}

.btn-music-left {
    right: 31%;
}

.wrapp-music-mobile {
    position: absolute;
    bottom: 50%;
    right: 2%;
    z-index: 10;
    cursor: pointer;
    opacity: 0.6;
}

.wrapp-button {
    position: absolute;
    bottom: 15%;
    z-index: 10;
    cursor: pointer;   
}

.link-detail-pc-right {
    display: inline-block;
    /* opacity: 70%; */
    top: 70%;
    z-index: 10;
    margin-left: 30px;
    padding: 10px;
    transition: 0.3s;
}

.link-detail-pc-left {
    display: inline-block;
    /* width: 90px; */
    padding: 10px;
    /* height: 50px; */
    bottom: 0;
    z-index: 10;
    margin-right: 80px;
}

.link-detail-pc-left a:hover, .link-detail-pc-right a:hover {
    opacity: 1;
}


.action-bar .btn svg {
    bottom: 0;
}

.action-bar .btn:not(:first-child) {
    margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
    color: #ccc;
    filter: drop-shadow(1px 1px 5px #000);
    cursor: pointer;
}

.action-bar .btn:active {
    filter: none !important;
}

.action-bar .btn.disabled {
    color: #666;
    pointer-events: none;
}

.flipbook {
    width: 90vw;
    height: 100vh;
}


.flipbook .viewport {
    width: 90vw !important;
    height: calc(100vh - 50px - 40px) !important;
}

.flipbook .bounding-box {
    box-shadow: 0 0 20px #000;
}

.pageNumLeft{
           position: absolute;
    color: #fff;
    /* bottom: 0; */
    right: 0;
      padding: 2px;
    font-size: 12px;
    background: #424242;
    width: 28px ;
    text-align: center;
}

.pageNumRight{
    text-align: center;
    width: 28px ;
        position: absolute;
    color: #fff;
    /* bottom: 0; */
    left: 0;
      font-size: 12px;
    background: #424242;
    padding: 2px;
    width: 28px ;
}

.pageNumWrapp{
    position: relative;
    cursor: pointer;
}

.video-icon{
    /* width: 50px; */
    /* padding-top: 40%; */

}

.bg-primary{
    background-color:#694177 !important;
}

.btn-primary{
    background-color:#694177 !important;
}

@media (min-width:1200px) {
    .flipbook {
        width: 75vw;
        height: 80%;
    }

    .flipbook .viewport {
        width: 75vw !important;
        height: calc(100vh - 50px - 40px) !important;
    }

}

@media (max-width:480px){
        .pageNumWrapp img {
    width: 100% !important;
    /* display: block; */
}
.video-icon {
    padding-top: 45%;
    padding-right: 8px;
}
}
</style>