<template>
    <div>
        
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Buffer } from 'buffer';

export default {

    name: 'MyHome',

    computed: {
        ...mapState({
            token: state => state.token
        })
    },

    created() {
        this.$store.commit('setContext', this)
        this.pageInit()

        const token = this.$cookies.get('bearer')

        if(token) {
            this.$store.commit('setToken', token)
            // this.getCartLines()
        }
    },

    async mounted() {
        const query = this.$route.query

        if(query.state) {
            try {
                const json = Buffer.from(query.state, "base64").toString();
                const state = JSON.parse(json)

                const response = await this.$axios.get("/users/oauth/google/callback", {
                    params: {
                        state: query.state,
                        code: query.code,
                        callback: window.location.protocol.concat("//").concat(window.location.hostname).concat("/"),
                    },
                });

                this.setCookie(response);
                
                this.$router.push(state.location.pathname)
                
                this.$toast.success("Berhasil masuk ke Aplikasi melalui google")
            } catch(error) {
                if(error.response && error.response.data) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('Gagal masuk ke Aplikasi melalui Google')
                }
            }
        }
    },

    methods: {
        ...mapActions({
            pageInit: "pageInit"
        }),

        setCookie: function (response) {
            // const expireTimes = 60 * 60 * 24 * process.env.VUE_APP_COOKIE_EXPIRES

            this.$cookies.set('bearer', response.data.token);
            this.$cookies.set('refreshTokenId', response.data.refreshTokenId);
            this.$cookies.set('refreshToken', response.data.refreshToken);
            this.$cookies.set('refreshTokenValidUntil', response.data.refreshTokenValidUntil);

            this.$store.commit('setToken', response.data.token)
        },
    }
}

</script>